import { useEffect, useState } from "react";
import { apiCall } from "../../../utils/serviceCall";
import {
  HTTPMethod,
  handleStore,
  parseDashboardDetailsPath,
} from "../../../utils/helper";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  DetailsState,
} from "../../../redux/stores/details/detailsReducer";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import SelectEditor from "./SelectEditor";

export default function MainCurators({ props, typeBasicInput }) {
  const [curators, setCurators] = useState<{ [key: string]: any }>();
  const [curatorCompanies, setCuratorCompanies] = useState<{
    [key: string]: any;
  }>();
  const [positionCode, setPositionCode] = useState<string>("");
  const intl = useIntl();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const [showInput, setShowInput] = useState<boolean>(false);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const location = useLocation();
  let pathstart = location.pathname;

  const [curatorCompaniesId, setCuratorCompaniesId] = useState(props.data);

  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);

  let structureStore: DetailsState = {
    [pathName]: {
      [pathId]: {
        form: {},
      },
    },
  };
  const updatePositionCode = async (value) => {
    const pathCuratorCompany = `/curator_companies/${curatorCompaniesId}`;
    try {
      let res = await apiCall(HTTPMethod.PATCH, `${pathCuratorCompany}`, {
        positionCode: value,
      });

      setPositionCode(value);
      handleStore(res.data, {
        pathName,
        pathId,
        formField: "curators",
      });
      // props.setValue(props.name, value, );
    } catch (err) {
      console.log(err);
    }
  };
  const getValue = async () => {
    const pathCuratorCompany = `/curator_companies/${curatorCompaniesId}`;
    try {
      const res = await apiCall(HTTPMethod.GET, `${pathCuratorCompany}`);
      setCuratorCompanies(res.data);
      setPositionCode(res.data.positionCode);
      if (res.data.curator) {
        const pathCurator = `/curators/${res.data.curator.split("/").pop()}`;
        const resCurator = await apiCall(HTTPMethod.GET, `${pathCurator}`);
        setCurators(resCurator.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (props.data) {
      getValue();
    }
  }, [curatorCompaniesId]);

  useEffect(() => {
    setCuratorCompaniesId(state[pathName]?.[pathId]?.form?.mainCurator?.id);
  }, [state[pathName]?.[pathId]?.form?.mainCurator]);

  useEffect(() => {
    if (curatorCompanies) {
      handleStore(curatorCompanies, {
        pathName,
        pathId,
        formField: "curators",
      });
    }
  }, [curatorCompanies]);
  const options = {
    CRT: "CRT",
    CUF: "CUF",
    COL: "COL",
    LIG: "LIG",
    CMS: "CMS",
    AMS: "AMS",
    AMG: "AMG",
    COZ: "COZ",
    COG: "COG",
    GSR: "GSR",
    PLQ: "PLQ",
    PRD: "PRD",
  };
  return (
    <>
      {curatorCompanies && curators && (
        <>
          <div
            onClick={() => !hash && navigate(`#curators/${curators.id}`)}
            className="mainCurators linkDetails boldSize"
          >
            {curators.fullName}
          </div>{" "}
          -{" "}
          <>
            {showInput ? (
              <SelectEditor
                props={{
                  name: "positionCode",
                  data: positionCode,
                  setValue: (name, value) => {
                    updatePositionCode(value);
                  },
                  setShowInput: (e) => {
                    setShowInput(e);
                  },
                  type: {
                    type: "string",
                    editor: {
                      translate: "CuratorTypology",
                      options: options,
                    },
                  },
                }}
              ></SelectEditor>
            ) : (
              <span onClick={() => setShowInput(true)}>
                {intl.formatMessage({
                  id: `CuratorTypology.${positionCode}`,
                })}
              </span>
            )}
          </>
          <div className="symbol symbol-50px me-1">
            <span className="badge badge-light fw-bold my-1">
              {positionCode}
            </span>
          </div>
        </>
      )}
    </>
  );
}

/* React */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";

/* Custom */
import { DynamicTypes, SettingDetail } from "../../typings/dn";
import { Tabs, TabulatingDetails } from "../../typings/tab";
import { DnDContainer, DnDDroppable, DnDDraggable } from "../DragNDrop";
import {
  EditDynamicComponentStructure,
  ViewDynamicComponentStructure,
  propsNestableTabsView,
} from "../dynamicComponent/ComponentEditorView";

/* Metronic */
import { KTIcon } from "../../vendor/_metronic/helpers";
import {
  deepFindFirstValueFromKey,
  generateUniqueId,
  parseDashboardDetailsPath,
} from "../../utils/helper";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";

const saveOnDragEnd = (
  result: DropResult,
  Items: TabulatingDetails[],
  setItems: React.Dispatch<React.SetStateAction<TabulatingDetails[]>>
) => {
  const { source, destination } = result;
  if (!destination) return;
  if (
    source.droppableId === destination.droppableId &&
    source.index === destination.index
  )
    return;

  if (source.droppableId === "tabs" && destination.droppableId === "tabs") {
    const newItems = Array.from(Items);
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);
    setItems(newItems);
  }
};
interface TabsProps {
  tabs?: Tabs["pages"];
  insideTabs?: boolean;
  propsNestable?: propsNestableTabsView;
  dataForDynamicEditor?: any;
  updateTabsFunc?: React.Dispatch<React.SetStateAction<Tabs["pages"]>>;
  selectedInnerComponent?: DynamicTypes;
  setSelectedInnerComponent?: React.Dispatch<
    React.SetStateAction<DynamicTypes | undefined>
  >;
  /**
   * set for show list of components on EditBar
   */
  setListComponent?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EditTabulatingLayout({
  tabs,
  insideTabs = false,
  ...props
}: TabsProps): React.ReactNode {
  const [tab, setTab] = useState(tabs?.length ? tabs[0].key : "");
  const [Items, setItems] = useState(tabs?.length ? tabs : []);
  const {
    setListComponent,
    selectedInnerComponent,
    setSelectedInnerComponent,
  } = props;
  const propsEditDynamicComponentStructure = {
    setListComponent: setListComponent,
    setSelectedComponent: setSelectedInnerComponent,
    selectedComponent: selectedInnerComponent,
  };
  useEffect(() => {
    // Updates the tabs in the parent component. allowing for the overview to be updated
    props.updateTabsFunc && props.updateTabsFunc(Items);

    // props updateTabsFunc is not included in the dependency array as it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Items]);

  return (
    <>
      <div className="card w-100">
        <DnDContainer
          onDragEnd={(result) => {
            saveOnDragEnd(result, Items, setItems);
          }}
          className="d-flex card-header card-header-stretch overflow-auto"
        >
          <DnDDroppable
            droppableId="tabs"
            direction="horizontal"
            className="nav nav-stretch nav-line-tabs fw-bold border-transparent align-items-end"
          >
            {Items.map((item, index) => {
              return (
                <DnDDraggable
                  draggableId={`tab-${index}`}
                  index={index}
                  className="nav-item"
                  key={`Tab ${index}`}
                >
                  <div
                    className={clsx(
                      `d-flex m-0 nav-link cursor-pointer justify-content-around`,
                      { active: tab === item.key }
                    )}
                    onClick={() => setTab(item.key)}
                    style={{
                      minWidth: "100px",
                      maxHeight: "36px",
                      color: `${tab === item.key ? "#C8C8C8" : "#FFFFFF"}`,
                      background: `${tab === item.key ? "#FFFFFF" : "#C8C8C8"}`,
                      borderTop: "1px solid #ECECEC",
                      borderRight: "1px solid #ECECEC",
                      borderLeft: "1px solid #ECECEC",
                      borderRadius: `${index === 0 ? "6px 0 0 0" : ""} ${
                        index === Items.length - 1 ? "0 6px 0 0" : ""
                      }`,
                    }}
                  >
                    <input
                      type="text"
                      size={item.displayName.length || 1}
                      className="form-control p-0 m-0"
                      style={{
                        color: `${tab === item.key ? "#C8C8C8" : "#FFFFFF"}`,
                        background: `${
                          tab === item.key ? "#FFFFFF" : "#C8C8C8"
                        }`,
                        border: "none",
                        width: "100%",
                        textAlign: "center",
                      }}
                      value={item.displayName}
                      onChange={(e) => {
                        const newItems = Array.from(Items);
                        newItems[index].displayName = e.target.value;
                        setItems(newItems);
                      }}
                    />
                    <button
                      className="btn p-0"
                      onClick={() => {
                        setItems(Items.filter((item, i) => i !== index));
                      }}
                    >
                      <KTIcon
                        iconName="trash"
                        className="fs-2 text-danger show-on-hover"
                      />
                    </button>
                  </div>
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
          <div className="d-flex justify-content-between">
            <button
              className="d-flex btn align-items-center p-3"
              onClick={() => {
                setItems([
                  ...Items,
                  {
                    key: `Tab${generateUniqueId()}`,
                    displayName: `Tab ${Items.length}`,
                    content: { Rows: [] },
                  },
                ]);
              }}
            >
              <KTIcon iconName="plus" className="fs-2 small-button" />
            </button>
          </div>
        </DnDContainer>

        <div className="card-body">
          <div className="tab-content">
            {Items.map((item, index) => {
              return (
                <div
                  className={clsx("tab-pane", { active: tab === item.key })}
                  key={`TabPane ${index}`}
                >
                  <EditDynamicComponentStructure
                    insideTabs
                    comps={{
                      order: item.content,
                      setOrder: ((newOrder: SettingDetail) => {
                        const newItems = Array.from(Items);
                        newItems[index].content = newOrder;
                        setItems(newItems);
                      }) as React.Dispatch<React.SetStateAction<SettingDetail>>,
                      ...propsEditDynamicComponentStructure,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export function ViewTabulatingLayout({
  tabs,
  insideTabs = false,
  ...props
}: TabsProps): React.ReactNode {
  const intl = useIntl();
  const [tab, setTab] = useState(tabs?.length ? tabs[0].key : "");
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const currentNumberElementStore =
    state[pathName]?.[pathId]?.["numberElement"];

  return (
    <>
      <div className="w-100">
        <div className="overflow-auto">
          {tabs && tabs?.length === 1 && (
            <div className="titleSingleTabs">{tabs[0].displayName}</div>
          )}
          {tabs && tabs?.length > 1 ? (
            <ul className="nav nav-stretch nav-line-tabs fw-bold border-transparent">
              {tabs?.map((item, index) => {
                const currentMain = deepFindFirstValueFromKey(
                  item.content,
                  "Main"
                );

                return (
                  <li
                    className="nav-item align-items-end "
                    key={`Tab ${index}`}
                  >
                    <div
                      className={clsx(
                        `d-flex m-0  px-5 nav-link cursor-pointer justify-content-around`,
                        { active: tab === item.key }
                      )}
                      onClick={() => setTab(item.key)}
                      style={{
                        display: "flex",
                        justifyContent: "space-Fbe",
                        minWidth: "100px",
                        gap: "5px",
                        maxHeight: "36px",
                        color: `${tab === item.key ? "black" : "#FFFFFF"}`,
                        borderBottom: "none",
                        background: `${
                          tab === item.key ? "#f8fafc" : "#C8C8C8"
                        }`,
                        borderTop: "1px solid #ECECEC",
                        borderRight: "1px solid #ECECEC",
                        borderLeft: "1px solid #ECECEC",
                        borderRadius: `${index === 0 ? "6px 0 0 0" : ""} ${
                          index === tabs?.length - 1 ? "0 6px 0 0" : ""
                        }`,
                      }}
                    >
                      <span>
                        {intl.formatMessage({ id: item.displayName })}
                      </span>
                      <span className="badge badge-secondary">
                        {currentNumberElementStore &&
                          currentMain &&
                          currentNumberElementStore[currentMain] && (
                            <>{currentNumberElementStore[currentMain]}</>
                          )}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <>
              {tabs && tabs[0]?.displayName !== "" ? (
                <div className="title-single-tab">
                  {intl.formatMessage({ id: tabs[0]?.displayName })}
                </div>
              ) : null}
            </>
          )}
        </div>

        <div className="tab-content">
          {tabs?.map((item, index) => {
            return (
              <div
                className={clsx("tab-pane", { active: tab === item.key })}
                key={`TabPane ${index}`}
              >
                <ViewDynamicComponentStructure
                  comps={{
                    order: item.content,
                    propsNestable: props.propsNestable,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

import React, { useCallback, useState, useEffect, Fragment } from "react";
import { useIntl } from "react-intl";
import {
  generateUniqueId,
  isoToFormattedDate,
  isoToFormattedTime,
} from "../../utils/helper";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
import { CompanyState } from "./CompanyState";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

export interface CompanyCardProps {
  company: {
    id: string;
    name: string;
    state: string;
    isFollower: boolean;
    isImportant: boolean;
    isUrgent: boolean;
    hasPhotos: boolean;
    photoNote?: string;
    relevance: number;
    codRea: string;
    court: string;
    alertDate: string;
    companyCurators: string[];
    procedureNumber: string;
    procedureTypology: string;
  };
  courts: [];
  curators?: [];
  companyCurators?: any[];
  index: number;
  toggleClickRow: (id: string, name: string) => void;
  title: string;
  time?: boolean;
  showState?: boolean;
  subLoading?: { curatorsLoading: boolean; courtsLoading: boolean };
}

export function CompanyCard({
  company,
  courts,
  curators,
  companyCurators,
  index,
  toggleClickRow,
  title,
  subLoading,
  time = true,
  showState = false,
}: CompanyCardProps) {
  const getBackgroundClass = () => {
    const now = new Date();

    let bgClass: string[] = [];
    if(company.state === CompanyState.CLOSED){
      bgClass.push('customBackgroundClosed');
    }else if(company.state === CompanyState.ACQUIRED){
      bgClass.push('customBackgroundAcquired');
    }else if(company.isUrgent){
      bgClass.push('customBackgroundUrgent');
    }else if(company.isImportant){
      bgClass.push('customBackgroundImportant');
    }

    if (company.state === CompanyState.NEW) {
      bgClass.push('customBorderCardNuova');
    } else if (
      company.alertDate && new Date( company.alertDate ) > now
    ) {
      bgClass.push('customBorderFuture');
    } else if (
      !company.alertDate || (company.alertDate && new Date( company.alertDate ) <= now)
    ) {
      bgClass.push('customBorderPast');
    } 

    if (company.state === CompanyState.ACQUIRED) {
      bgClass.push('customBorderAcquired');
    } 
    if (company.state === CompanyState.CLOSED) {
      bgClass.push('customBorderGray');
    }
    return bgClass;
  };

  const intl = useIntl();

  const procedureNumber = () => {
    if (company.procedureNumber) {
      return (
        <div className="p-2">
          {/*<div className="badge badge-primary fw-semibold fs-7 ms-2 text-wrap">*/}
          <div className="fw-semibold">- {company.procedureNumber}</div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const state = () => {
    if (company.state) {
      return (
        <div className="p-2 text-capitalize">
          <div className="fw-bold">
            {intl.formatMessage({
              id: `CompanyState.${company.state}`,
            })}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const procedureTypology = () => {
    if (company.procedureTypology) {
      return (
        <div className="p-2 text-capitalize">
          {/*<div className="badge badge-primary fw-semibold fs-7 px-2 ms-2">*/}
          <div className="fw-semibold ms-2">
            {company.procedureTypology.length > 3
              ? company.procedureTypology
              : intl.formatMessage({
                  id: `ProcedureTypology.${company.procedureTypology}`,
                })}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const rowCuratorsReference = useCallback(
    (iri: string, positionCode?: string) => {
      if (curators) {
        const curatorsArray: {
          "@id": string;
          firstName: string;
          lastName: string;
          id: string;
        }[] = Object.values(curators);

        const referenceFound = curatorsArray.find(
          (curator) => curator["@id"] === iri
        );
        if (referenceFound) {
          return (
            <div className="d-flex align-items-sm-center">
              <div className="symbol symbol-50px me-1">
                <span className="badge badge-light fw-bold my-1">
                  {positionCode}
                </span>
              </div>
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    onClick={() =>
                      toggleClickRow && toggleClickRow(referenceFound.id, "curators")
                    }
                    className="text-gray-800 text-hover-primary fs-6 fw-bold cursor-pointer"
                  >
                    {referenceFound.firstName} {referenceFound.lastName}
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    },
    [curators]
  );

  const rowCuratorsCompanyReference = useCallback(
    (iri: string, idx: number) => {
      if (companyCurators) {
        const companyCuratorsArray: {
          "@id": string;
          curator: string;
          positionCode: string;
        }[] = Object.values(companyCurators);
        const referenceFound = companyCuratorsArray.find(
          (companyCurator) => companyCurator["@id"] === iri
        );
        if (subLoading?.curatorsLoading) {
          return <Skeleton></Skeleton>;
        }
        if (referenceFound!) {
          return (
            <>
              {referenceFound &&
                referenceFound.curator &&
                rowCuratorsReference(
                  referenceFound.curator,
                  referenceFound.positionCode
                )}
            </>
          );
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    },
    [companyCurators, curators]
  );

  const rowCourtsReference = useCallback(
    (iri: string) => {
      if (courts) {
        const courtsArray: { "@id": string; name: string }[] =
          Object.values(courts);
        const referenceFound = courtsArray.find(
          (court) => court["@id"] === iri
        );
        if (subLoading?.courtsLoading) {
          return <div style={{width:'100%'}}><Skeleton style={{width:'100%',height:'10px'}}></Skeleton></div>;
        }
        if (referenceFound) {
          return <>- {referenceFound.name}</>;
          // return <><i className="fa-solid fa-gavel fa-xs icon-editor"></i>  {referenceFound.name}</>;
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    },
    [courts]
  );

  const formattedDateTimeAlert = useCallback((inputDate) => {
    if (inputDate) {
      const formattedDate = isoToFormattedDate(inputDate);
      const formattedTime = isoToFormattedTime(inputDate);
      if (time && formattedDate && formattedTime && formattedTime !== "00:00") {
        return (
          <>
            <div className="d-flex align-items-sm-center">
              <div className="symbol symbol-50px me-2">
                {/* <i className="fs-3 fas fs-1 fa-regular fa-bell"></i> */}
                <img
                  src={toAbsoluteUrl("/assets/notifications.svg")}
                  style={{ width: "20px", height: "20px" }}
                ></img>
              </div>
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">{formattedTime}</div>
              </div>
            </div>
            <div className="d-flex align-items-sm-center">
              <div className="symbol symbol-50px me-2">
                {/* <i className="fs-3 fas fs-1 fa-regular fa-calendar"></i> */}
                <img
                  src={toAbsoluteUrl("/assets/calendario.svg")}
                  style={{ width: "20px", height: "20px" }}
                ></img>
              </div>
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">{formattedDate}</div>
              </div>
            </div>
          </>
        );
      } else if (time && formattedDate && formattedTime === "00:00") {
        return (
          <div className="d-flex align-items-sm-center">
            <div className="symbol symbol-50px me-2">
              {/* <i className="fs-3 fas fs-1 fa-regular fa-calendar"></i> */}
              <img
                src={toAbsoluteUrl("/assets/calendario.svg")}
                style={{ width: "20px", height: "20px" }}
              ></img>
            </div>
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1 me-2">{formattedDate}</div>
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }, []);

  return (
    <div
      className={clsx('card mb-xl-2', getBackgroundClass())} 
      key={`${index}+${generateUniqueId()}`}
    >
      <div className="card-body p-2">
        <div className="d-flex flex-stack border-bottom border-3">
          <div
            className="fs-6 fw-bold p-1 text-gray-900 text-capitalize text-hover-primary cursor-pointer"
            onClick={() =>toggleClickRow && toggleClickRow(company.id, "companies")}
          >
            {company.name}
          </div>

          <div className="d-flex flex-row mb-3">
            {company.hasPhotos ? (
              <div className="p-1">
                {company.photoNote ? (
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-top`}>{company.photoNote}</Tooltip>
                    }
                  >
                    <i
                      className="fs-3 fas fs-1 fa-solid fa-image"
                      style={{ color: "#00659d" }}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <i
                    className="fs-3 fas fs-1 fa-solid fa-image"
                    style={{ color: "#00659d" }}
                  ></i>
                )}
              </div>
            ) : (
              <></>
            )}
            {company.isFollower ? (
              <div className="p-1">
                <i
                  className="fs-3 fas fs-1 fa-solid fa-heart"
                  style={{ color: "#ED5F5F" }}
                ></i>
              </div>
            ) : (
              ""
            )}

            {company.isImportant ? (
              <div className="p-1">
                <i
                  className="fs-3 fas fs-1 fa-solid fa-star"
                  style={{ color: "#FFA800" }}
                ></i>
              </div>
            ) : (
              ""
            )}

            {company.isUrgent ? (
              <div className="p-1">
                <i
                  className="fs-3 fas fs-1 fa-solid fa-bolt"
                  style={{ color: "#C51B9F" }}
                ></i>
              </div>
            ) : (
              ""
            )}

            <div className=" fs-3 fw-bold text-primary">
              {company.relevance}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row mb-2">
          {/*<div className="p-2">*/}
          {/*  <a*/}
          {/*    href={`https://www.google.com/search?q=${encodeURIComponent(*/}
          {/*      company.name*/}
          {/*    )}`}*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <i*/}
          {/*      className="fa-brands fa-google fs-2 "*/}
          {/*      style={googleIconStyle}*/}
          {/*    ></i>*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="d-flex align-items-center flex-wrap">
            {showState && state()}
            {procedureTypology()}
            {procedureNumber()}
            {company.court && rowCourtsReference(company.court)}
          </div>
        </div>

        <div className="d-flex flex-row">
          <div className="col-8">
            {company.companyCurators.map((el, idx) => (
              <div key={`companyReference-${idx}-${index}`}>
                {rowCuratorsCompanyReference(el, idx)}
              </div>
            ))}
          </div>
          <div className="col-4">
            {formattedDateTimeAlert(company.alertDate)}
          </div>
        </div>

        {/* <div className="d-flex flex-stack flex-wrapr">
          <div className="symbol symbol-50px me-2">
            <img
              src={toAbsoluteUrl("/assets/TribunaleDashboard.svg")}
              style={{ width: "20px", height: "20px" }}
            ></img>
          </div>
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="fs-6 fw-semibold text-gray-600 mb-5"></div>
            {company.court && (
              <div className="flex-grow-1 fw-semibold text-gray-600">
                {rowCourtsReference(company.court)}
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

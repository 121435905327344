import React, { FC, useEffect, useState } from "react";

import { apiCall } from "../../utils/serviceCall";
import { store } from "../../redux/store";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
// import { ViewDynamicComponentStructure } from "../../utils";
import {
  HTTPMethod,
  parseDashboardDetailsPath,
  typeOfObject,
} from "../../utils/helper";
import { parseDataFromGlobalContext } from "../../redux/stores/rootContext";
import { ViewDynamicComponentStructure } from "../../models/dynamicComponent/ComponentEditorView";
import { SettingDetail } from "../../typings/dn";
import { fetchInitialOrder } from "../../models/DragNDrop/common";
import { useDispatch } from "react-redux";

import { useIntl } from "react-intl";
import {
  MessageTypeEnum,
  MessagesReducerTypeActions,
} from "../../redux/stores/message/messageReducer";
import clsx from "clsx";
import CustomOffCanvas from "../CustomOffCanvas/CustomOffCanvas";
import { statusDetailReducerTypeActions } from "../../redux/stores/statusDetail/statusDetailReducer";
interface panelCanvasProps {
  hashData?: {
    route: string;
    id: string;
  };
  onHide: () => void;
  children?: any;
}
const exclude: string[] = ["@context", "@id", "@type", "id"];
const filterFields = (fieldsToFilter: typeOfObject): typeOfObject => {
  return Object.keys(fieldsToFilter)
    .filter((key) => !exclude.includes(key))
    .reduce((obj, key) => {
      obj[key] = fieldsToFilter[key];
      return obj;
    }, {});
};

const PanelCanvas: FC<panelCanvasProps> = ({ hashData, onHide, children }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [savedRoute, setSavedRoute] = useState<string>();
  //location
  const location = useLocation();
  let pathstart = location.pathname;
  const { path, pathId, pathName, pathParent } =
    parseDashboardDetailsPath(pathstart);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const globalState = store.getState();

  const [detailData, setdetailData] = useState<{ [key: string]: any }>();
  const [order, setOrder] = useState<SettingDetail | null>(null);
  const parsedData = parseDataFromGlobalContext(
    globalState.rootContext.apiDocs
  );

  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  let dataToUtilize: { path: string; pathId: any | null; pathName: string } = {
    path: "",
    pathId: null,
    pathName: "",
  };
  if (hashData) {
    dataToUtilize.pathId = hashData.id ? hashData.id : null;
    dataToUtilize.pathName = hashData.route;
  } else {
    if (path || path === "") {
      dataToUtilize.pathId = pathId;
      dataToUtilize.pathName = pathName;
    }
  }

  const currentEntity: any = parsedData!.find(
    (item) => item.name === dataToUtilize.pathName
  );
  const allFields = filterFields(currentEntity.fields);
  const setStatusDetails = () => {
    dispatch({
      type: statusDetailReducerTypeActions.SET,
      payload: { modifiedDetails: true },
    });
  };
  const saveForm = async (value: {
    [key: string]: any;
    updateByItself?: boolean | "makeGet";
  }): Promise<void> => {
    setStatusDetails();
    const nameCurrentFieldChange: string = Object.keys(value)[0];
    if (value.updateByItself === true) {
      setdetailData((prevState) => ({
        ...prevState,
        ...{
          [nameCurrentFieldChange]: value[nameCurrentFieldChange],
        },
      }));
      return;
    }
    if (value.updateByItself === "makeGet") {
      if (parsedData) {
        initializeDetail();
      }
      return;
    }

    if (detailData && detailData[nameCurrentFieldChange]) {
      const prevValue = detailData[nameCurrentFieldChange];
      const newValue = value[nameCurrentFieldChange];
      if (prevValue === newValue) {
        return;
      }
    }
    if (dataToUtilize.pathId) {
      try {
        await apiCall(
          HTTPMethod.PATCH,
          `/${dataToUtilize.pathName}/${dataToUtilize.pathId}`,
          value
        );
        // setdetailData(data.data)
        if (parsedData) {
          initializeDetail();
        }
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch {
        console.log("alert");
      }
    } else {
      const preAssesstmentsRequired =
        hashData?.route === "pre_assesstments"
          ? { companyPreAssesstment: `/api/${pathName}/${pathId}`, ...value }
          : null;
      try {
        const res = await apiCall(
          HTTPMethod.POST,
          `/${dataToUtilize.pathName}`,
          preAssesstmentsRequired ? preAssesstmentsRequired : value
        );
        // setdetailData(data.data)
        navigate(`#${res.data["@id"].split("/api/").pop()}`);
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch {
        console.log("alert");
      }
    }
  };
  const initializeDetail = async () => {
    setLoading(true);
    setSavedRoute(location.pathname);
    try {
      const { data } = await apiCall(
        HTTPMethod.GET,
        `/${dataToUtilize.pathName}/${dataToUtilize.pathId}`
      );
      setdetailData(data);
    } catch (error) {
      console.log(error, "<----- errore");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (parsedData) {
      if (!savedRoute || !location.pathname.includes(savedRoute)) {
        if (dataToUtilize.pathId) {
          setdetailData(undefined);
          initializeDetail();
        }
        fetchInitialOrder(dataToUtilize.pathName, setOrder);
      }
    }
  }, [location, globalState.rootContext.apiDocs]);
  useEffect(() => {
    const offcanvasElements = document.querySelectorAll(
      ".offcanvas-backdrop.fade.show"
    );
    if (offcanvasElements && offcanvasElements.length > 1) {
      for (let i = 1; i < offcanvasElements.length; i++) {
        offcanvasElements[i].remove();
      }
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setShowOffcanvas(true);
    }, 1000);
  }, [hashData]);

  return (
    <>
      {hashData ? (
        <>
          <CustomOffCanvas
            onExit={() => navigate(-1)}
            className={clsx(
              `offcanvas-${dataToUtilize.pathName}`,
              "second-panel"
            )}
            showOffcanvas={showOffcanvas}
            entity={detailData}
          >
            {detailData && order && (
              <ViewDynamicComponentStructure
                comps={{
                  order: order,
                  propsNestable: {
                    nameDetail: dataToUtilize.pathName,
                    dataFields: detailData,
                    allFields: allFields,
                    outputInput: saveForm,
                  },
                }}
              />
            )}
          </CustomOffCanvas>
        </>
      ) : (
        <>
          <CustomOffCanvas
            withEdit
            direction="right"
            onExit={() =>  navigate(`/${pathParent}`)}
            className={clsx(
              `offcanvas-${dataToUtilize.pathName}`,
              "second-panel"
            )}
            showOffcanvas={showOffcanvas}
            entity={detailData}
          >
            {detailData && order && (
              <ViewDynamicComponentStructure
                comps={{
                  order: order,
                  propsNestable: {
                    nameDetail: dataToUtilize.pathName,
                    dataFields: detailData,
                    allFields: allFields,
                    outputInput: saveForm,
                  },
                }}
              />
            )}
          </CustomOffCanvas>
        </>
      )}
      <div
        id={"drawer-${dataToUtilize.pathName}"}
        onClick={() => {
          hashData ? navigate(-1) : navigate(`/${pathParent}`);
        }}
        className={clsx("drawer-overlay", `drawer-${dataToUtilize.pathName}`)}
        style={{ zIndex: "109px" }}
      ></div>
    </>
  );
};

export default PanelCanvas;

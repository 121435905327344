/* React */
import { DropResult } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";

/* Utils */
import { DynamicComponentType } from ".";
import DynamicComponent, { dcts } from "./ComponentParser";
import { EditTabulatingLayout } from "../tabs/TabsPanel";
import { Tabs, TabulatingDetails } from "../../typings/tab";
import { DynamicTypes, SettingDetail } from "../../typings/dn";
import { DnDContainer, DnDDroppable, DnDDraggable } from "../DragNDrop";
import {
  EditDrawer,
  ToggleDrawer,
} from "../toolbar/editableDrawer/ToggleEditDrawer";
import {
  handleRowDelete,
  handleRowAdd,
  handleColumnDelete,
  handleColumnAdd,
  handleClassNameChange,
  handleChangeMain,
} from "../DragNDrop/common";

/* Metronic */
import { KTIcon } from "../../vendor/_metronic/helpers";
import clsx from "clsx";
import CustomButton from "../buttons/CustomButton";

import { generateUniqueId, typeOfObject } from "../../utils/helper";
import DynamicEditor from "./DynamicEditor/DynamicEditor";
import TabsView from "./Editors/TabsView";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { CompanyState } from "../../pages/dashboard/CompanyState";

const saveOnDragEnd = async (
  result: DropResult,
  order: SettingDetail,
  setOrder: React.Dispatch<React.SetStateAction<SettingDetail>>
) => {
  const { destination, source } = result;
  if (!destination) return;
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  const newOrder = Array.from(order.Rows);
  if (source.droppableId !== "Rows") {
    const [removed] = newOrder[source.droppableId].Columns.splice(
      source.index,
      1
    );
    newOrder[destination.droppableId].Columns.splice(
      destination.index,
      0,
      removed
    );
  } else {
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);
  }

  const updatedOrder = {
    ...order,
    Rows: newOrder,
  };
  setOrder(updatedOrder);
};
export interface propsNestableTabsView {
  nameDetail: string;
  outputInput: (value: any) => Promise<void>;
  /**
   * list of all Fields
   */
  allFields: typeOfObject | null;
  /**
   * data of details
   */
  dataFields: typeOfObject;
}

interface DynamicComponentParsingProps {
  insideTabs?: boolean;
  /**
   * Link to the Viewbale Drawer
   */
  title?: string;
  /**
   * Component order and functions
   */
  comps: {
    propsNestable?: propsNestableTabsView;
    /**
     * set for show list of components on EditBar
     */
    setListComponent?: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * Order of the dynamic components to be displayed
     */
    order: SettingDetail;
    /**
     * Function to set the order of the dynamic components
     */
    setOrder?: React.Dispatch<React.SetStateAction<SettingDetail>>;
    /**
     * The selected dynamic component to be edited
     */
    selectedComponent?: DynamicTypes;
    /**
     * Select the dynamic component to be edited
     */
    setSelectedComponent?: React.Dispatch<
      React.SetStateAction<DynamicTypes | undefined>
    >;
  };
}
interface positionSelected {
  order: SettingDetail;
  rowindex: number;
  columnindex?: number;
}
export function EditDynamicComponentStructure({
  comps,
  insideTabs,
  ...props
}: DynamicComponentParsingProps) {
  const { selectedComponent, setSelectedComponent, setListComponent } = comps;
  const [order, setOrder] = useState<SettingDetail>(comps.order);
  const [IsMain, setIsMain] = useState(false);
  const [sectionOrderToAddClassName, setSectionOrderToAddClassName] =
    useState<positionSelected | null>(null);
  const [sectionOrderToAddMain, setSectionOrderToAddMain] =
    useState<any | null>(null);
  const [classNameText, setClassNameText] = useState<string>("");
  const intl = useIntl();
  const chooseSetOrder = comps.setOrder ? comps.setOrder : setOrder;
  const propsTabulating = {
    setListComponent: setListComponent,
  };
  const handleClose = () => {
    setSectionOrderToAddClassName(null);
    setClassNameText("");
  };
  useEffect(() => {
    if (sectionOrderToAddClassName) {
      if (sectionOrderToAddClassName.columnindex !== undefined) {
        setClassNameText(
          sectionOrderToAddClassName.order.Rows[
            sectionOrderToAddClassName.rowindex
          ].Columns[sectionOrderToAddClassName.columnindex!].ClassNameCol!
        );
      } else {
        if (
          sectionOrderToAddClassName.order.Rows[
            sectionOrderToAddClassName.rowindex
          ].ClassNameRow
        ) {
          setClassNameText(
            sectionOrderToAddClassName.order.Rows[
              sectionOrderToAddClassName.rowindex
            ].ClassNameRow!
          );
        }
      }
    }
  }, [sectionOrderToAddClassName]);
  useEffect(() => {
    if (sectionOrderToAddMain) {
      if (sectionOrderToAddMain.columnindex !== undefined) {
        updateOrder(
          handleChangeMain(
            sectionOrderToAddMain!.order!,
            sectionOrderToAddMain?.rowindex!,
            sectionOrderToAddMain?.columnindex,
            sectionOrderToAddMain.nameDynamicComponent
          )
        );
      }
    }
  }, [sectionOrderToAddMain]);
  function updateOrder(newOrder: SettingDetail) {
    if (comps.setOrder) {
      comps.setOrder(newOrder);
    }
    setOrder(newOrder);
  }
  const chooseIcon = (element: string): string | false => {
    if (element.includes("space-")) {
      return "fa-solid fa-arrows-left-right-to-line";
    }
    if (element.includes("horizontal-line-")) {
      return "fa-solid fa-arrows-down-to-line";
    }
    if (element.includes("vertical-line-")) {
      return "fa-solid fa-arrows-up-down";
    }
    return false;
  };
  useEffect(() => {
    setOrder(comps.order);
  }, [comps.order]);

  return (
    <div className="card m-2">
      <DnDContainer
        onDragEnd={(result) => {
          saveOnDragEnd(result, order, chooseSetOrder);
        }}
        key="Rows"
        style={{
          backgroundColor: "transparent",
          margin: "0px",
          padding: "0px",
        }}
      >
        <DnDDroppable droppableId="Rows" key="DropZones">
          {order.Rows.map((row, rowIndex) => (
            <DnDDraggable
              draggableId={`${rowIndex}`}
              index={rowIndex}
              key={`Element-${rowIndex}`}
              className="draggable"
              grip={
                <div className="grip grip-row">
                  <div
                    onClick={() =>
                      setSectionOrderToAddClassName({
                        order,
                        rowindex: rowIndex!,
                      })
                    }
                  >
                    <i className="fa-solid fa-brush text-light"></i>
                  </div>
                  <div
                    onClick={() => {
                      updateOrder(handleRowDelete(order, rowIndex));
                    }}
                  >
                    <i className="fs-3 fas fa-trash-alt text-light"></i>
                  </div>
                </div>
              }
            >
              <DnDContainer
                onDragEnd={(result) => {
                  saveOnDragEnd(result, order, chooseSetOrder);
                }}
                key={`Row-${rowIndex}`}
                style={{
                  border: "1px dashed rgba(64, 66, 84, 1)",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  maxWidth: "100%",
                  top: "-10px",
                }}
                className="card card-body bg-white container p-0"
              >
                <DnDDroppable
                  droppableId={`${rowIndex}`}
                  key={`DropZone-${rowIndex}`}
                  direction="horizontal"
                  style={{ display: "flex" }}
                  className="row-droppable"
                >
                  {row.Columns.map((column, columnIndex) => (
                    <DnDDraggable
                      draggableId={`${columnIndex}`}
                      index={columnIndex}
                      key={`Element-${rowIndex}-${columnIndex}`}
                      style={{
                        margin: "10px",
                        /* Apply glow effect if this component is selected */
                        border:
                          selectedComponent === column.DynamicComponent
                            ? "1px solid black"
                            : "none",
                      }}
                      grip={
                        <i
                          className="fas fa-grip-vertical text-primary grip"
                          style={{ cursor: "grab", paddingRight: 10 }}
                        ></i>
                      }
                      className="d-flex align-items-center justify-content-between rounded bg-light-primary p-5 draggable "
                    >
                      {!(dcts(column.DynamicComponent) === "Tabs") ? (
                        !chooseIcon(column.DynamicComponent as string) ? (
                          <>{column.DynamicComponent}</>
                        ) : (
                          <i
                            className={
                              chooseIcon(
                                column.DynamicComponent as string
                              ) as string
                            }
                          ></i>
                        )
                      ) : (
                        <EditTabulatingLayout
                          tabs={(column.DynamicComponent as Tabs).pages}
                          updateTabsFunc={
                            ((newOrder: TabulatingDetails[]) => {
                              const updatedRows = [...order.Rows];
                              updatedRows[rowIndex].Columns[
                                columnIndex
                              ].DynamicComponent = {
                                pages: newOrder || [],
                              };
                              updateOrder({ Rows: updatedRows });
                            }) as React.Dispatch<
                              React.SetStateAction<TabulatingDetails[]>
                            >
                          }
                          setSelectedInnerComponent={setSelectedComponent}
                          selectedInnerComponent={selectedComponent}
                          {...propsTabulating}
                        />
                      )}
                      <div
                        className="grip"
                        style={{
                          columnGap: 5,
                          marginLeft: 10,
                          display: "flex",
                        }}
                      >
                        {insideTabs && (
                          <button
                            className="btn btn-active-color-gray-900"
                            style={{ padding: 0 }}
                            onClick={() =>
                              setSectionOrderToAddMain({
                                order,
                                rowindex: rowIndex!,
                                columnindex: columnIndex,
                                nameDynamicComponent:column.DynamicComponent
                              })
                            }
                          >
                            {column.Main ? (
                              <i className="fa-solid fa-bookmark"></i>
                            ) : (
                              <i className="fa-regular fa-bookmark"></i>
                            )}
                          </button>
                        )}
                        <button
                          className="btn btn-active-color-gray-900"
                          style={{ padding: 0 }}
                          onClick={() =>
                            setSectionOrderToAddClassName({
                              order,
                              rowindex: rowIndex!,
                              columnindex: columnIndex,

                            })
                          }
                        >
                          <i className="fa-solid fa-brush"></i>
                        </button>
                        <button
                          className="btn btn-active-color-gray-900"
                          style={{ padding: 0 }}
                          onClick={() => {
                            updateOrder(
                              handleColumnDelete(order, rowIndex, columnIndex)
                            );
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                        <button
                          className="btn p-0 m-0 btn-active-color-gray-900"
                          onClick={() => {
                            setListComponent?.(true);
                            setSelectedComponent?.(column.DynamicComponent);
                          }}
                        >
                          <i className="fas fa-pencil"></i>
                        </button>
                      </div>
                    </DnDDraggable>
                  ))}
                </DnDDroppable>
                <button
                  className="d-flex btn"
                  onClick={() => {
                    updateOrder(handleColumnAdd(order, rowIndex));
                  }}
                >
                  <KTIcon iconName="plus" className="fs-2 text-info" />
                </button>
              </DnDContainer>
            </DnDDraggable>
          ))}
        </DnDDroppable>
        <div className="d-flex justify-content-between">
          <CustomButton
            onClick={() => {
              updateOrder(handleRowAdd(order));
            }}
          >
            <KTIcon iconName="plus" className="fs-1" /> Add Row
          </CustomButton>
        </div>
      </DnDContainer>
      <>
        <Modal
          show={sectionOrderToAddClassName ? true : false}
          onHide={() => handleClose()}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <label htmlFor="insertclass"></label>
            <input
              className="form-control form-control-solid"
              type="text"
              value={classNameText}
              name="insertclass"
              onChange={(e) => setClassNameText(e.target.value)}
            ></input>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              disable={!sectionOrderToAddClassName}
              onClick={() => {
                updateOrder(
                  handleClassNameChange(
                    sectionOrderToAddClassName!.order!,
                    sectionOrderToAddClassName?.rowindex!,
                    classNameText,
                    sectionOrderToAddClassName?.columnindex
                  )
                );
                handleClose();
              }}
            >
              {intl.formatMessage({ id: "Attachments.save" })}
            </CustomButton>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export const ViewDynamicComponentStructure = ({
  comps,
}: DynamicComponentParsingProps) => {
  const { propsNestable } = comps;
  return (
    <>
      {comps.order.Rows.map((row, rowIndex) => (
        <div
          className={row.ClassNameRow ? row.ClassNameRow : ""}
          style={{ display: "flex", padding: "0px", gap: "5px" }}
          key={`Row-${rowIndex}`}
        >
          {row.Columns.map((column, columnIndex) => {
            /** Dynamic component string */
            const dcs = dcts(column.DynamicComponent);
            return (
              <div
                key={`Column-${rowIndex}-${columnIndex}`}
                className={column.ClassNameCol ? column.ClassNameCol : ""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  maxWidth: "100%",
                }}
              >
                {propsNestable &&
                !(dcts(column.DynamicComponent) === "Tabs") ? (
                  dcs.includes("space-") ||
                  dcs.includes("horizontal-line-") ||
                  dcs.includes("vertical-line-") ? (
                    <>
                      {dcs.includes("space-") && (
                        <div style={{ flex: 1, height: "20px" }}></div>
                      )}
                      {dcs.includes("horizontal-line-") && (
                        <div
                          style={{
                            flex: 1,
                            height: "5px",
                            backgroundColor: "#c8c8c8",
                          }}
                        ></div>
                      )}
                      {dcs.includes("vertical-line-") && (
                        <div
                          style={{
                            width: "1px",
                            height: "100%",
                            justifySelf: "end",
                            marginLeft: "50%",
                            backgroundColor: "#cce5ff",
                          }}
                        ></div>
                      )}
                    </>
                  ) : (
                    dcs &&
                    propsNestable.allFields &&
                    propsNestable.dataFields && (
                      <>
                        <DynamicEditor
                          nameDetail={propsNestable.nameDetail}
                          type={
                            propsNestable.allFields[
                              column.DynamicComponent as string
                            ]
                          }
                          data={
                            propsNestable.dataFields[
                              column.DynamicComponent as string
                            ]
                          }
                          nameField={column.DynamicComponent as string}
                          dataFields={propsNestable.dataFields}
                          outputInput={(value) =>
                            propsNestable.outputInput(value)
                          }
                        />
                      </>
                    )
                  )
                ) : (
                  <TabsView
                    className={column.ClassNameCol ? column.ClassNameCol : ""}
                    order={column.DynamicComponent as Tabs}
                    propsNestable={propsNestable}
                  ></TabsView>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

interface EditBarProps {
  choices: string[];
  edit?: {
    component?: DynamicTypes;
    listComponent: boolean;
    setListComponent: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedComponent: React.Dispatch<
      React.SetStateAction<DynamicTypes | undefined>
    >;
    order: SettingDetail;
  };
}

export function EditBar({ choices, edit, ...props }: EditBarProps) {
  const checkIfUsed = (fieldName: string): boolean => {
    const rows = edit?.order?.Rows || [];

    return rows.some((row) => {
      return row.Columns.some((column) => {
        const dynamicComponent = column.DynamicComponent;

        if (dcts(dynamicComponent) === "Tabs") {
          const tabs = dynamicComponent as Tabs;

          return tabs.pages.some((page) => {
            return page.content.Rows.some((innerrow) => {
              return innerrow.Columns.some((innercolumn) => {
                return dcts(innercolumn.DynamicComponent) === fieldName;
              });
            });
          });
        } else {
          return dcts(dynamicComponent) === fieldName;
        }
      });
    });
  };
  const updateDynamicComponent = (component: any, fieldName: string) => {
    if (component.DynamicComponent === edit?.component) {
      component.DynamicComponent = fieldName as DynamicTypes;
    }
  };

  const updateInnerColumns = (columns: any[], fieldName: string) => {
    columns.forEach((innercolumn) => {
      updateDynamicComponent(innercolumn, fieldName);
    });
  };

  const updateInnerRows = (rows: any[], fieldName: string) => {
    rows.forEach((innerrow) => {
      updateInnerColumns(innerrow.Columns, fieldName);
    });
  };

  const updateTabsPages = (tabs: any, fieldName: string) => {
    tabs.pages.forEach((page) => {
      updateInnerRows(page.content.Rows, fieldName);
    });
  };

  const selectedComponent = (fieldName: string) => {
    edit?.order?.Rows?.forEach((row) => {
      row.Columns.forEach((column) => {
        if (dcts(column.DynamicComponent) === "Tabs") {
          updateTabsPages(column.DynamicComponent as Tabs, fieldName);
        } else {
          updateDynamicComponent(column, fieldName);
        }
      });
    });
    edit?.setListComponent?.(false);
    edit?.setSelectedComponent?.(undefined);
  };
  return (
    <div className={"edit-bar"}>
      {edit?.listComponent && (
        <>
          <h1 className={"title-edit-bar"}>Componenti</h1>
          {choices.map((fieldName) => {
            return (
              <p
                key={fieldName}
                className={clsx(
                  "label-edit-bar"
                )}
                onClick={() => {
                  !checkIfUsed(fieldName) && selectedComponent(fieldName);
                }}
              >
                {fieldName}
              </p>
            );
          })}
          <h1 className={"title-edit-bar"}>Azioni</h1>
          <p
            className="label-edit-bar"
            onClick={() => {
              selectedComponent("Tabs");
            }}
          >
            {" "}
            aggiungi Tabs
          </p>
          <p
            className="label-edit-bar"
            onClick={() => {
              selectedComponent(`space-${generateUniqueId()}`);
            }}
          >
            {" "}
            aggiungi Spazio
          </p>
          <p
            className="label-edit-bar"
            onClick={() => {
              selectedComponent(`horizontal-line-${generateUniqueId()}`);
            }}
          >
            {" "}
            aggiungi Linea Orizzontale
          </p>
          <p
            className="label-edit-bar"
            onClick={() => {
              selectedComponent(`vertical-line-${generateUniqueId()}`);
            }}
          >
            {" "}
            aggiungi Linea Verticale
          </p>
        </>
      )}
    </div>
  );
}

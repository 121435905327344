import { FC, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import moment from "moment";
import {
  HTTPMethod,
  hydra,
  notificationTypes,
  parseDashboardDetailsPath,
  saveNumberOfElementOnStore,
  takeIdFromIri,
  typeOfObject,
} from "../../../../utils/helper";
import { apiCall } from "../../../../utils/serviceCall";
import { useSelector } from "react-redux";
import { CombineReducersState, store } from "../../../../redux/store";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import UserAutocomplete from "../../../Input/UserAutocomplete";
import { Paginator } from "../../../paginator/paginator";
import { UserImage } from "../../../UserImage/UserImage";

import OutsideClickWrapper from "../../../OutsideWrapper/OutsideWrapper";
import { CompanyControllerApi } from "../../../../apis/companyController";
import { CompanyUserControllerApi } from "../../../../apis/companyUserController";


const MessageContent = ({
  showInput,
  messageModified,
  setMessageModified,
  children,
  handleClickOutside,
}) => {
  return showInput ? (
    <OutsideClickWrapper
      onClickOutside={() => {
        handleClickOutside();
      }}
    >
      <textarea
        className="p-5 rounded text-dark w-100"
        style={{ minWidth: "400px", height: "200px" }}
        value={messageModified}
        onChange={(e) => {
          setMessageModified(e.target.value);
        }}
      ></textarea>
    </OutsideClickWrapper>
  ) : (
    children
  );
};
export default function ChatWithTagEditor({ props }) {
  const intl = useIntl();
  const [listUser, setListUser] = useState<typeOfObject[] | null>(null);
  const [selectedListUser, setSelectedListUser] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [important, setImportant] = useState<boolean>(false);
  const [privateNote, setPrivateNote] = useState<boolean>(false);
  const [messageModified, setMessageModified] = useState<string>();
  const [messages, setMessages] = useState<typeOfObject[]>([]);
  const [currentView, setCurrentView] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);

  const { path } = parseDashboardDetailsPath(pathstart);
  const companieId = path.split("/").pop();
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const [idToModifier, setIdToModifier] = useState<number | null>(null);
  const currentUser = userData.id;
  const currentUserRoles = userData.roles;
  const chatbox = useRef<HTMLDivElement>(null);
  const group = userData.groupUser?.[0]?.group["@id"];
  const sendNotification = async (noteId: number) => {
    await apiCall(HTTPMethod.POST, "/notifications", {
      params: message,
      company: "/api" + path,
      note: noteId,
      type: notificationTypes.NEWNOTE,
    })
      .then(async (response) => {
        const notificationsId = response.data["@id"];
        await Promise.all(
          selectedListUser.map((userId) =>
            sendNotificationUser(notificationsId, userId)
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderTaggedUser = (taggedUsers, direction, idMessage) => {
    const classDirectionIn = direction === "in" ? "tagged-users-in" : "";
    if (taggedUsers.length === 0) return <></>;
    return (
      <div className={clsx("tagged-users", classDirectionIn)}>
        <span className="material-icons">groups</span>
        <div className={clsx("tagged-user")}>
          {taggedUsers.map((taggedUser) => taggedUser.fullName).join(", ")}
        </div>
      </div>
    );
  };
  const renderCurator = (curator, direction, idMessage) => {
    const classDirectionIn = direction === "in" ? "tagged-users-in" : "";
    if (!curator) return <></>;
    return (
      <div className={clsx("tagged-curators text-muted", classDirectionIn)}>
        <span className="material-icons">gavel</span>
        <div className={clsx("tagged-curator")}>
          {curator.fullName}
        </div>
      </div>
    );
  };
  const sendNotificationUser = async (
    notificationId: string,
    userId: string
  ) => {
    await apiCall(HTTPMethod.POST, "/notification_users", {
      user: userId,
      notification: notificationId,
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const sendMessage = async () => {
    setMessage("");
    setImportant(false);
    setPrivateNote(false);
    await apiCall(HTTPMethod.POST, "/notes", {
      content: message,
      user: `/api/users/${currentUser}`,
      company: "/api" + path,
      group: group,
      createdAt: moment().format(),
      important: important,
      private: privateNote, 
    })
      .then(async (response) => {
        setCurrentPage(1);
        if (selectedListUser.length > 0) {
          await sendNotification(response.data["@id"]);
        }
        props.setValue(
          props.name,
          [...props.data, response.data["@id"]],
          "makeGet"
        );
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const modifyMessage = async () => {
    await apiCall(HTTPMethod.PATCH, `/notes/${idToModifier}`, {
      content: messageModified,
    })
      .then((response) => {
        props.setValue(props.name, [...props.data], "makeGet");
        setMessageModified("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteMessage = async (id: number) => {
    await apiCall(HTTPMethod.DELETE, `/notes/${id}`)
      .then(() => {
        const listUpadated = props.data.filter((el) => el.id !== id);
        props.setValue(props.name, listUpadated, "makeGet");
        setMessage("");
      })
      .catch((err) => console.log(err));
  };
  const checkIfIsOut = (direction: string): boolean => {
    return direction === "out";
  };
  const initializeChats = async () => {
    setLoading(true);
    const params = {
      params: {
        company: companieId,
        page: currentPage,
        itemsPerPage: 1000,
        group: group,
        filterPrivate: true,
        "order[createdAt]": "desc",
      },
    };
    await apiCall(HTTPMethod.GET, "/notes", params)
      .then((response) => {
        saveNumberOfElementOnStore(
          pathstart,
          props.name,
          response.data[hydra.TOTAL]
        );
        setMessages(response.data[hydra.MEMBER]);
        setCurrentView(response.data[hydra.VIEW]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    // Prefill all followers for notes if user is admin
    if(currentUserRoles?.includes('ROLE_ADMIN') && companieId){
      let companyData = await CompanyControllerApi.getCompanyById(companieId);
      let companiesUser = companyData?.data?.companiesUser;
      const listIdWithoutDuplicateFiltrated = companiesUser.map(
        (element) => takeIdFromIri(element)
      );

      console.debug(`Searching followers for company ${companieId} with group ${group}. Excluding user: ${currentUser}`);
      let followers = await CompanyUserControllerApi.getCompanyUsers({id: listIdWithoutDuplicateFiltrated, "user.groupUser.group": group, "user.active": true, "exists[deletedAt]": false});
      const followersData = followers.data[hydra.MEMBER];
      setSelectedListUser(takeSelectionUsers(followersData));
    }
  };
  /**
   * Filters a list of followers removing currently logged user then it maps them
   * with a list of object containing a value (the iri) and a label (the fullName).
   *
   * @param followersData - List of followers for current company
   * @returns A list of objects with value and label to user for selection component
   */
  const takeSelectionUsers = (followersData: any[]): any[] => {
    const followersWithoutCurrentUser = followersData.filter((element) => takeIdFromIri(element.user['@id']) != currentUser);
    return followersWithoutCurrentUser.map((element) => ({value: element.user['@id'], label: element.user['fullName']}));
  }
  const checkType = (user: typeOfObject): string => {
    return takeIdFromIri(user["@id"]) === currentUser ? "in" : "out";
  };
  const renderText = (content, userInfo) => {
    return (
      <div
        className={clsx(
          "p-5 rounded w-100",
          "text-dark",
          "text-start"
          // `text-${checkIfIsOut(checkType(userInfo)) ? "start" : "end"}`
        )}
        data-kt-element="message-text"
        dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }}
      ></div>
    );
  };
  const DropDownAction = ({ id, content }) => {
    return (
      <>
        <span
          onClick={(e) => {
            e.preventDefault();
            window.confirm(intl.formatMessage({ id: "window.delete" })) &&
              deleteMessage(id);
          }}
          className="material-icons-two-tone"
        >
          delete
        </span>
        <span
          className="material-icons-two-tone"
          onClick={() => {
            setMessageModified(content);
            setIdToModifier(id);
          }}
        >
          edit
        </span>
      </>
    );
  };
  const handleClickOutsideModified = (prevMessage) => {
    setIdToModifier(null);
    if (prevMessage === messageModified) {
      return;
    }

    modifyMessage();
  };
  useEffect(() => {
    initializeChats();
  }, [props.data, currentPage, state[pathName]?.[pathId]?.form?.notesByMail]);

  const dateSection = (message) => {
    return (
      <span className="text-muted fs-7 mb-1">
        {message.createdAt && message.updatedAt ? (
          message.createdAt === message.updatedAt ? (
          <>creato il {moment(message.createdAt).format("D/MM/YYYY HH:mm")}</>
          ) : (
            <>
              creato il {moment(message.createdAt).format("D/MM/YYYY HH:mm")} |
                modificato il {moment(message.updatedAt).format("D/MM/YYYY HH:mm")}{" "}
            </>
          )
        ) : (
          "-"
        )}
      </span>
    );
  };
  const { messageIsEmpty } = useMemo(() => {
    return {
      messageIsEmpty: message.trim() === "",
    };
  }, [message]);
  return (
    <div
      className="flex-lg-row-fluid"
      style={{
        borderRadius: "12px",
        padding: "0 42px",
      }}
    >
      <div id="kt_chat_messenger">
        <div id={"kt_chat_messenger_body"}>
          <div className={clsx("me-n5 pe-5", "h-300px h-lg-auto")}>
            <div className="card-footer pt-4" id={"kt_chat_messenger_footer"}>
              <div className="w-75 py-5 mx-auto d-flex flex-column gap-2">
                <label className="boldNormalSize">
                  {intl.formatMessage({ id: "Action.tagUser" })}
                </label>
                <UserAutocomplete
                  value={selectedListUser}
                  setValue={setSelectedListUser}
                  iriList={selectedListUser}
                ></UserAutocomplete>
              </div>
              <div
                className="w-75 mx-auto d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #E5F2FF",
                  borderRadius: "12px",
                  padding: "5px",
                }}
              >
                <textarea
                  className="form-control form-control-flush mb-3 fw-normal fs-base"
                  rows={4}
                  data-kt-element="input"
                  placeholder={intl.formatMessage({ id: "NOTES.TYPENOTES" })}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <div
                  style={{
                    backgroundColor: !messageIsEmpty ? "#3699FF" : "#d6eaff",
                    padding: "2px",
                    borderRadius: "4px",
                    width: "25px",
                    height: "25px",
                    textAlign: "center",
                  }}
                  onClick={(e) => {

                   if(e.detail === 1){!messageIsEmpty && sendMessage();}
                  }}
                >
                  <i
                    className="fa-solid text-white fa-angles-right"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
              </div>
              <div className="w-75 py-5 mx-auto d-flex flex-row gap-2">
                <input
                  className="form-check-input p-1"
                  type="checkbox"
                  checked={important}
                  onChange={(e) => {
                    console.debug("Important note checkbox changed: " + e.target.checked);
                    setImportant(e.target.checked);
                  }}
                />
                <p className="m-0">{intl.formatMessage({ id: "NOTES.IMPORTANT" })}</p>
                <input
                  className="form-check-input p-1"
                  type="checkbox"
                  checked={privateNote}
                  onChange={(e) => {
                    console.debug("Private note checkbox changed: " + e.target.checked);
                    setPrivateNote(e.target.checked);
                  }}
                />
                <p className="m-0">{intl.formatMessage({ id: "NOTES.PRIVATE" })}</p>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  background: "#CCE5FF",
                  marginTop: "9px",
                  marginBottom: "20px",
                }}
              ></div>
            </div>

            <div ref={chatbox}></div>
            <div
              className="d-flex flex-column mb-5 text-break"
              style={{  }}
            >
              {loading ? (
                <div
                  className="spinner-border mx-auto p-5"
                  style={{ color: "#369BFF" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  {" "}
                  {messages &&
                    messages.length > 0 &&
                    messages.map((message, index) => {
                      const userInfo = message.user;
                        userInfo.id = takeIdFromIri(userInfo["@id"]);

                      if (userInfo) {
                        const state =
                          checkType(userInfo) === "in" ? "info" : "primary";
                        const templateAttr = {};
                        const contentClass = `d-flex justify-content-${
                          checkIfIsOut(checkType(userInfo)) ? "start" : "end"
                        } mb-10`;
                        return (
                          <div
                            key={`message${index}`}
                            className={clsx("d-flex", contentClass, "mb-10")}
                            {...templateAttr}
                          >
                            <div
                              className={clsx(
                                "d-flex flex-row align-items w-100",
                                `align-items-${
                                  checkIfIsOut(checkType(userInfo))
                                    ? "start"
                                    : "end"
                                }`
                              )}
                            >
                              <div className={clsx("d-flex align-items-center mb-2 w-100", `flex-${
                                  checkIfIsOut(checkType(userInfo))
                                    ? "start"
                                    : "end"
                                }`)}>
                                {checkIfIsOut(checkType(userInfo)) ? (
                                  <>
                                    <div className="me-3 w-75">
                                      <div className="d-flex flex-start align-items-center ">
                                        <p className="fs-5 fw-bolder text-gray-900 mb-0 text-hover-primary me-5">
                                          {userInfo.firstName &&
                                          userInfo.lastName ? (
                                            <>
                                              {userInfo.firstName}{" "}
                                              {userInfo.lastName}
                                            </>
                                          ) : (
                                            userInfo.fullName && (
                                              <>{userInfo.fullName}</>
                                            )
                                          )}
                                        </p>
                                        {dateSection(message)}
                                      </div>
                                      <div
                                        style={{
                                          borderRadius: "12px",
                                          backgroundColor: message.important ? "#FFFDD0" : "#E5F2FF",
                                        }}
                                        className={clsx(
                                          "d-flex",
                                          "align-items-center",
                                          "px-4",
                                          "py-2"
                                        )}
                                      >
                                        {
                                          <UserImage
                                            credential={{
                                              id:userInfo.id,
                                              name: userInfo.firstName,
                                              surname: userInfo.lastName,
                                            }}
                                          ></UserImage>
                                        }
                                        <MessageContent
                                          setMessageModified={
                                            setMessageModified
                                          }
                                          messageModified={messageModified}
                                          showInput={
                                            idToModifier === message.id
                                          }
                                          handleClickOutside={() =>
                                            handleClickOutsideModified(
                                              message.content
                                            )
                                          }
                                        >
                                          {renderText(
                                            message.content,
                                            userInfo
                                          )}
                                        </MessageContent>
                                      </div>
                                      {renderTaggedUser(
                                        message.taggedUsers,
                                        checkType(userInfo),
                                        index
                                      )}
                                      {renderCurator(
                                        message.curator,
                                        checkType(userInfo),
                                        index
                                      )}
                                    </div>
                                    {message.private &&
                                    <div className="w-2">
                                        <span
                                          className="material-icons-two-tone"
                                        >
                                          lock
                                        </span>
                                    </div>}
                                  </>
                                ) : (
                                  <>
                                    {message.private &&
                                    <div className="w-2">
                                            <span
                                              className="material-icons-two-tone"
                                            >
                                              lock
                                            </span>
                                    </div>}
                                    <div className="ms-3 w-75">
                                      <div className="d-flex gap-2 flex-end align-items-center">
                                        <div
                                          className={clsx(
                                            "settigns-icon",
                                            "settings-icon-in"
                                          )}
                                        >
                                          <DropDownAction
                                            id={message.id}
                                            content={message.content}
                                          />
                                        </div>
                                        {dateSection(message)}
                                        <p className="fs-5 fw-bolder text-gray-900 mb-0 text-hover-primary ms-5">
                                              {userInfo.firstName &&
                                                userInfo.lastName ? (
                                                  <>
                                                    {userInfo.firstName}{" "}
                                                    {userInfo.lastName}
                                                  </>
                                                ) : (
                                                  userInfo.fullName && (
                                                    <>{userInfo.fullName}</>
                                                  )
                                                )}
                                        </p>
                                      </div>
                                      <div
                                        className={clsx(
                                          "d-flex flex-end",

                                          "align-items-center",
                                          "px-4",
                                          "py-2"
                                        )}
                                        style={{
                                          borderRadius: "12px",
                                          backgroundColor: message.important ? "#FFFDD0" : "#CCE5FF",
                                        }}
                                      >
                                        <MessageContent
                                          setMessageModified={
                                            setMessageModified
                                          }
                                          messageModified={messageModified}
                                          showInput={
                                            idToModifier === message.id
                                          }
                                          handleClickOutside={() =>
                                            handleClickOutsideModified(
                                              message.content
                                            )
                                          }
                                        >
                                          {renderText(
                                            message.content,
                                            userInfo
                                          )}
                                        </MessageContent>
                                        <UserImage
                                          credential={{
                                            id:userInfo.id,
                                            name: userInfo.firstName,
                                            surname: userInfo.lastName,
                                          }}
                                        ></UserImage>
                                      </div>
                                      {renderTaggedUser(
                                        message.taggedUsers,
                                        checkType(userInfo),
                                        index
                                      )}
                                      {renderCurator(
                                        message.curator,
                                        checkType(userInfo),
                                        index
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {messages && messages.length === 0 && (
                    <div className="empty-element">
                      <i className="fa-regular fa-file"></i>
                      <p>{intl.formatMessage({ id: "Empty.notes" })}</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="py-4">
              <Paginator
                key={`page-${currentPage}`}
                view={currentView}
                currentPage={currentPage}
                callback={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { HTTPMethod } from "../utils/helper";
import { apiCall, apiCallFile } from "../utils/serviceCall";
import { ApiPlatformWrapper, User } from "./entities";

/**
 * API Controller for User entity
 */
export class UserControllerApi {
  private static AVATAR_CACHE = 'AVATAR_CACHE';

  /**
   * Uploads the avatar for current user
   * @param formData - FormData with photo to upload
   * @returns The found company
   */
  static async uploadAvatar(formData: FormData) {
    return apiCallFile(HTTPMethod.POST, `/user/me/avatar`, formData);
  }

  /**
   * Gets the current user
   * @returns The current user
   */
  static async me(): Promise<ApiPlatformWrapper<User>> {
    return apiCall(HTTPMethod.GET, `/user/me`);
  }
  /**
   * Avatar (if exists) of the user with given id
   * @param id - Id of the avatar
   * @returns The avatar of given user
   */
  static async avatar(id: number): Promise<any> {
    try {
      const result = await apiCall(HTTPMethod.GET, `/user/${id}/avatar?${UserControllerApi.readAvatarCache()}`, {
        headers: "accept: image/jpeg",
        responseType: "blob",
      });
      return result;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  static clearAvatarCache() {
    localStorage.removeItem(UserControllerApi.AVATAR_CACHE);
  }

  private static readAvatarCache() {
    let val = localStorage.getItem(UserControllerApi.AVATAR_CACHE);
    if(val == null) {
      val = new Date().getTime()+'';
      localStorage.setItem(UserControllerApi.AVATAR_CACHE, val);
    }
    return val;
  }
}

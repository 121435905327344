import clsx from "clsx";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FocusWrapper from "../FocusWrapper/FocusWrapper";
import { parseDashboardDetailsPath } from "../../utils/helper";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import Notification from "../Notification/Notification";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
import NotificationIcon from "../NotificationIcon/NotificationIcon";
import { useDispatch } from "react-redux";
import { NotificationReducerTypeActions } from "../../redux/stores/notification/notificationReducer";
import { CompanyState } from "../../pages/dashboard/CompanyState";

enum DIRECTION {
  right = "end",
  left = "start",
}
interface CustomOffCanvasProps {
  showOffcanvas: boolean;
  children?: ReactNode;
  className?: string;
  direction?: "left" | "right";
  onExit: () => void;
  withEdit?: boolean;
  entity?: any;
}

const CustomOffCanvas: FC<CustomOffCanvasProps> = ({
  showOffcanvas,
  children,
  className,
  direction = "left",
  onExit,
  withEdit,
  entity
}) => {
  const navigate = useNavigate();
  const { userData } = useSelector(
    (state: CombineReducersState) => state.userReducer
  );
  const dispatch = useDispatch();
  const setShowAlarms = (value) => {
    dispatch({
      type: NotificationReducerTypeActions.SET_NOTIFICATION,
      payload: { showAlarms: value },
    });
  };
  const { notification, showAlarms, showIconAlarms } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const [showNotification, setShowNotification] = useState(false);

  const isCompany = entity && entity['@type'] == 'Companies';
  const companyClasses = {
    "customBackgroundClosed": isCompany && entity.state == CompanyState.CLOSED,
    "customBackgroundAcquired": isCompany && entity.state == CompanyState.ACQUIRED,
    "customBackgroundUrgent": isCompany && entity.isUrgent,
    "customBackgroundImportant": isCompany && entity.isImportant
  };
  return (
    <>
      <div
        className={clsx(
          className,
          "bg-body drawer",
          `drawer-${DIRECTION[direction]}`,
          showOffcanvas && "drawer-on"
        )}
        data-kt-drawer="true"
        data-kt-drawer-name="activities"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="false"
        data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
        data-kt-drawer-toggle="#kt_activities_toggle"
        data-kt-drawer-close="#kt_activities_close"
        style={{ zIndex: 500 }}
      >
        <div className={clsx("header-offcanvas",companyClasses)}>
          {withEdit &&
            (userData?.roles?.includes("ROLE_ADMIN") ||
              process.env.REACT_APP_DEV === "true") && (
              <div
                onClick={() => {
                  navigate(`/${pathName}/edit`, { state: { id: pathId } });
                }}
              >
                <i className="fa-solid fa-pencil cursor-pointer"></i>
              </div>
            )}
          <div onClick={() => onExit()}>
            <i className="fa-solid fa-xmark cursor-pointer"></i>
          </div>
        </div>
        <div className={clsx("offcanvas-body",companyClasses)}> {children}</div>
      </div>
    </>
  );
};

export default CustomOffCanvas;
